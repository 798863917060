export function isEmpty(value) {
  if (value == null || value === undefined) {
    return true;
  }
  if (value && value.length) return false;
  return true;
}

// https://stackoverflow.com/a/37826698/1991244
export function chunk(array, size) {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function first(array) {
  return (array && array.length) ? array[0] : undefined;
}

export function last(array) {
  return [].concat(array).pop()
}

export function isUndefined(value) {
  return (typeof value === 'undefined' || value === undefined)
}

export function isNull(value) {
  return value === null
}

export function isNil(value) {
  return value == null
}

export function throttle(func, timeFrame) {
  let lastTime = 0;
  return function (...args) {
    const now = new Date();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
}

export function get(obj, path, defaultValue = undefined) {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

const sortByFunction = (key) => {
  return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
}
export function sortBy(array, key) {
  return array.concat().sort(sortByFunction(key))
}

// Polyfill for find (IE support)
if (!Array.prototype.find) {
  Array.prototype.find = function (predicate) {
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}
