const customCanonical = process.env.GATSBY_CUSTOM_CANONICAL
// TODO: fix -- it seems the env variable is ignored when building?
const canonicalSiteUrl = customCanonical || "https://www.charles.co";
const siteUrl =
  process.env.NODE_ENV === `development`
    ? "http://localhost:8000"
    : process.env.SCALINGO_BUILD === `true`
      ? `https://${process.env.APP}.${process.env.REGION_NAME}.scalingo.io`
      : canonicalSiteUrl;

const frontAppUrl = process.env.GATSBY_FRONT_APP_URL;
const omsAppUrl = process.env.GATSBY_OMS_SERVER_URL

module.exports = {
  canonicalSiteUrl,
  frontAppUrl,
  omsAppUrl,
  siteUrl, // Site domain. Do not include a trailing slash!
  siteName: 'Charles',

  logo: '/images/charles-social.jpg',
  blogPrefix: '/blog',

  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires
  // sometimes to delete the cache)

  siteTitleMeta: 'Charles.co', // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: 'Conseils et diagnostic en ligne. Ordonnances si nécessaires par des médecins specialistes des troubles sexuels, digestifs, du sommeil et de la chute de cheveux. Échanges confidentiels avec un spécialiste. Service sûr & discret. Livraison à domicile. Service Patient 24/7. Livraison 48H.',
  websiteSexoDescriptionMeta: 'Consultation en ligne en 24h avec des médecins spécialistes en sexologie. Livraison de traitements à domicile via une pharmacie française en 48h.',
  websiteGenericDescription: 'Consultation en ligne en 24h avec des médecins spécialistes en sexologie, troubles du sommeil, de la digestion et de la chute de cheveux. Livraison de traitements à domicile via une pharmacie française en 48h.',
  organizationSlogan: 'Conseils et diagnostic en ligne. Ordonnances si nécessaires par des médecins specialistes des troubles sexuels, digestifs, du sommeil et de la chute de cheveux. Échanges confidentiels avec un spécialiste. Service sûr & discret. Livraison à domicile. Service Patient 24/7. Livraison 48H.',
  organizationDescription: 'Consultation en ligne en 24h avec des médecins spécialistes en sexologie, troubles du sommeil, de la digestion et de la chute de cheveux. Livraison de traitements à domicile via une pharmacie française en 48h.',
  shareImage: '/images/charles-social.jpg', // fallback image for meta data. Will be used, when no post/tag/author image specified. 1200x1200 is recommended
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 1200, // Change to the height of your default share image

  shortTitle: 'Charles.co', // Used for App manifest e.g. Mobile Home Screen
  backgroundColor: '#FFF', // Used for Offline Manifest
  themeColor: '#000', // Used for Offline Manifest

  twitter: '@charlescofr',
  facebook: 'charlescofr',
  facebookAppId: '891268654262273',

  legalName: 'Charles SAS',
  youtubeChannelUrl: 'https://www.youtube.com/channel/UCpEfF_Jfp0SozH6uZ0PNw5Q',
  linkedInUrl: 'https://www.linkedin.com/company/charleshealth',
  infogreffeUrl: 'https://www.infogreffe.com/entreprise-societe/844332130-charles-750118B29550.html',
  googleSearchUrl: 'https://www.google.com/search?q=charles.co',
  tiktokUrl: 'https://www.tiktok.com/@docteurscharles.co',
  instragramUrl: 'https://www.instagram.com/charles.co/',

  fullAddress: '112 avenue de Paris, 94300 Vincennes Cedex',
  legalAddress: '112 avenue de Paris',
  legalPostcode: '94300',
  legalCity: 'Vincennes',
  phoneNumber: '+33186651733',
  bestRating: 5,
  worstRating: 1,
  ratingValue: 4.9,
  ratingCount: 500
};
